import React from 'react'
import {Button, Modal} from 'react-bootstrap'
import {Link} from 'gatsby'
import 'components/css/modal.css'
import {Span} from 'components'

function Sales() {
  return (
    <div className="modal-view">
      <Link to="/careers" state={{ modal: false }}>
        <Button className="close" id="close-button">
         <Span aria-hidden="true">x</Span>
        </Button>
      </Link>
      <h2>SENIOR SOLUTIONS ENGINEER</h2><hr/>
    <br/>
      <Modal.Title><h4>MINIMUM QUALIFICATIONS</h4></Modal.Title>
      <Modal.Body>
        <ul>
          <li>High proficiency on Ruby on Rails</li>
          <li>Good people management skills, will lead team of back end and front end developers</li>
          <li>Able to work with minimal supervision</li>
          <li>Strong logic sense</li>
          <li>Strong analytical and planning skills</li>
          <li>Good communication and presentation skills</li>
          <li>Excellent problem-solving skills</li>
          <li>At least 5 years experience in software development</li>
        </ul>
      </Modal.Body>
      <a href="mailto: careers@digipay.ph" className="resume"><Button className="resume-button">Send Resume</Button></a>
    </div>
  )
}

export default Sales
